import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import Title from "../components/Title/Title";
import CardNews from "../components/Cards/CardNews";
import SmartText from "../utils/TextHandler";
import SmartImage from "../utils/ImageHandler";
import Link from "../components/Global/Link";
import Pagination from "../components/Global/Pagination";

// ====

function trimSlash(path) {
  return path.replace(/\/$/, "");
}

const News = ({ blok, data, pageContext }) => {
  let { numPages, limit, skip, currentPage, archive, homepage } = pageContext;
  let doc;
  let pageSlug;
  let items;
  if (data) {
    //    doc = JSON.parse(data.index.content);
    doc = JSON.parse(pageContext.story.content);
    pageSlug = trimSlash(pageContext.story.full_slug);
    items = data.news;
  } else {
    doc = blok;
  }
  const openGraph = {
    type: "website",
    title: `${archive ? doc.archive_title : doc.title}`,
    description: `${archive ? doc.archive_body : doc.body}`,
    image: doc.image,
  };

  return (
    <Layout>
      <Meta openGraph={openGraph} meta={doc.meta} />
      <section className="py-5">
        <div className="container">
          {archive === true ? (
            <>
              {doc.archive_title && (
                <Title
                  title={doc.archive_title}
                  description={doc.archive_body && doc.archive_body}
                />
              )}
            </>
          ) : (
            <>
              <Title title={doc.title} description={doc.body && doc.body} />
            </>
          )}
          <div className="row gy-5 justify-content-start">
            {items.nodes.map((node, index) => {
              const item = JSON.parse(node.content);

              let size = "3";

              if (!archive && index === 0) {
                size = "6";
              }
              if (
                (!archive && index === 5) ||
                (!archive && index === 6) ||
                (!archive && index === 11)
              ) {
                size = "6";
              }

              return (
                <div className={`col-12 col-lg-${size}`}>
                  <CardNews
                    item={item}
                    size="lg"
                    border={false}
                    preview={true}
                    link={node.full_slug}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {numPages && numPages > 1 && (
        <Pagination
          numPages={numPages}
          currentPage={currentPage}
          archive={
            doc.archive_path ? pageSlug + "/" + doc.archive_path : "archive"
          }
          index={pageSlug ? pageSlug : "news"}
        />
      )}
    </Layout>
  );
};

export default News;

export const QUERY = graphql`
  query NewsIndexDefaultQuery($skip: Int!, $limit: Int!) {
    # Query the data from the index page
    index: storyblokEntry(
      field_component: { eq: "news" }
      lang: { eq: "default" }
    ) {
      content
    }

    # Grab news articles
    news: allStoryblokEntry(
      filter: { field_component: { eq: "news_post" } }
      sort: {
        fields: [field_featured_boolean, field_date_string]
        order: [DESC, DESC]
      }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        full_slug
        field_date_string
        content
      }
    }
  }
`;

News.propTypes = {
  pageContext: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  blok: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

News.defaultProps = {
  pageContext: null,
  blok: null,
  data: null,
};
