import React from "react";
import PropTypes from "prop-types";
import SmartText from "../../utils/TextHandler";
import SmartImage from "../../utils/ImageHandler";
import Image from "../Global/Image";
import SmartTag from "../../utils/TagHandler";
import Link from "../Global/Link";
import Moment from "react-moment";

const CardNews = (props) => {
  const { item, size, border, preview, link } = props;

  return (
    <div
      className={`row justify-content-between position-relative ${
        border === true ? "border-bottom mb-4 pb-4" : "mb-0 pb-0"
      }`}
    >
      <div
        className={`${size === "sm" ? "col-4 order-2" : "col-12 mb-3 order-0"}`}
      >
        {size === "sm" ? (
          <div className="ratio ratio-1x1">
            <Image
              src={
                (item.image_preview && item.image_preview.filename) ||
                (item.image && item.image.filename) ||
                //                (item.game && item.game.content.image.filename) ||
                null
              }
              sm="175x175"
              md="115x115"
              lg="175x175"
              alt={item.title}
              className="brand-radius w-100"
            />
          </div>
        ) : (
          <div className="ratio ratio-16x9">
            <Image
              src={
                (item.image_preview && item.image_preview.filename) ||
                (item.image && item.image.filename) ||
                //                (item.game && item.game.content.image.filename) ||
                null
              }
              sm="520x293"
              md="384x216"
              lg="700x394"
              alt={item.title}
              className="brand-radius w-100"
            />
          </div>
        )}
      </div>
      <div
        className={`col-12 mb-3 small ${size === "sm" ? "order-0" : "order-1"}`}
      >
        {item.type && (
          <span className="badge brand-background-pink brand-text-white">
            {SmartTag(item.type)}
          </span>
        )}

        {item.game && item.game.content && (
          <>
            <span className="mx-2 brand-text-black-50">|</span>
            <span>{item.game.content.title}</span>
          </>
        )}
      </div>
      <div className={`${size === "sm" ? "order-1 col" : "order-2 col-12"}`}>
        {item.title_preview ? (
          <h3 className="fw-bold mb-3">{item.title_preview}</h3>
        ) : (
          <h3 className="fw-bold mb-3">{item.title}</h3>
        )}
        {preview && (
          <>
            {item.body_preview ? (
              <SmartText method="text" className="mb-3 d-none d-md-block">
                {item.body_preview}
              </SmartText>
            ) : (
              <SmartText
                method="text"
                limit="100"
                className="mb-3 d-none d-md-block"
              >
                {item.body}
              </SmartText>
            )}
          </>
        )}
        <Moment
          format="Do MMMM, YYYY"
          date={item.date}
          className="brand-text-black-50 small"
        />
        {link && <Link to={link} stretched />}
      </div>
    </div>
  );
};

export default CardNews;

CardNews.propTypes = {
  settings: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CardNews.defaultProps = {
  settings: null,
};
